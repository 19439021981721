<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card-title class="title">
            <v-icon>layers</v-icon>
            Units
            <v-spacer></v-spacer>
            <add-button permission="subject-read" @action="form.dialog = true"
              >Add unit
            </add-button>
          </v-card-title>

          <v-card flat>
            <v-card-title class="title">
              <v-flex xs4>
                <v-text-field
                  hint="Atleast 3 characters"
                  flat
                  color="primary"
                  class="pa-0"
                  label="Search By Name"
                  v-model="searchAH"
                  @input="searchUnit"
                />
              </v-flex>
            </v-card-title>
          </v-card>

          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :rows-per-page-items="rowsPerPageItems"
            :pagination.sync="pagination"
            :total-items="form.items.meta.total"
          >
            <template slot="items" slot-scope="props">
              <td>{{ ++props.index }}</td>
              <td class="text-xs-left">{{ props.item.name }}</td>
              <td class="text-xs-left">{{ props.item.short_code }}</td>
              <td class="text-xs-right">
                <span v-if="props.item.company_id">
                  <edit-button
                    permission="subject-read"
                    @agree="form.edit(props.item), editData(props.item)"
                  />
                  <delete-button
                    permission="section-create"
                    @agree="form.delete(props.item.id)"
                  />
                </span>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="form.dialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="title primary white--text"
          >Unit Information</v-card-title
        >
        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="save"
            @keydown.native="form.errors.clear($event.target.name)"
            @keyup.enter="save"
            lazy-validation
          >
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="form.name"
                    name="name"
                    label="Name"
                    :error-messages="form.errors.get('name')"
                    :height="25"
                    autocomplete="off"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="form.short_code"
                    name="short_code"
                    label="Short Code"
                    :error-messages="form.errors.get('short_name')"
                    :height="25"
                    autocomplete="off"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="warning"
            outline
            flat
            @click="(form.dialog = false), form.reset()"
            >Cancel</v-btn
          >
          <v-btn color="success" outline flat @click="save">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Form from "@/library/Form";

const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();

export default {
  data: () => ({
    form: new Form(
      {
        name: "",
        short_code: "",
        description: "",
        is_primary: "",
        parent_id: "",
      },
      "/api/account/inventory/units"
    ),
    searchAH: null,
    search: null,
    isLoading: false,
    rowsPerPageItems: [5, 10, 25, 50, 75, 100],
    pagination: {
      rowsPerPage: 10,
    },
    entries: [],
    accountHeads: [],
    headers: [
      { text: "#", align: "left", sortable: false, value: "id", width: "20px" },
      { text: "Name", value: "name", align: "left", width: "30%" },
      { text: "Short Code", value: "short_code", align: "left", width: "30%" },
      {
        text: "Actions",
        value: "action",
        sortable: false,
        align: "right",
        width: 120,
      },
    ],
  }),

  computed: {
    ...mapState(["batch"]),
    items() {
      return this.entries.map((entry) => {
        return { Name: entry.name, id: entry.id };
      });
    },
  },

  watch: {
    pagination: function () {
      this.get();
    },
    batch: function (value) {
      this.get();
    },
    search(val) {
      if (!val) return;
      // if (this.isLoading) return;

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.isLoading = true;
        this.$rest
          .get("/api/account/inventory/warehouse?parent=1&search=" + val)
          .then(({ data }) => {
            // this.entries = data.data
            this.entries = data.data.map((res) => {
              return { name: res.name, id: res.id };
            });
          })
          .catch((err) => {})
          .finally(() => (this.isLoading = false));
      }, 500);
    },
  },

  methods: {
    queryString() {
      let json = this.pagination;
      return (
        "?" +
        Object.keys(json)
          .map(function (key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
              );
          })
          .join("&") +
        "&search=" +
        this.searchAH
      );
    },

    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
        this.accountHeads = data.data.map((item) => ({
          value: item.id,
          text: item.name,
        }));
      });
    },
    editData(data) {
      this.entries = [];
      this.entries.push({ name: data.parent_name, id: data.parent_id });
    },

    save() {
      if (this.form.is_primary) this.form.is_primary = 1;
      else this.form.is_primary = 0;
      // this.form.parent_id = this.form.parent_id.id;
      this.form
        .store()
        .then((res) => {
          this.$events.fire("notification", {
            message: res.data.message,
            status: "success",
          });
        })
        .catch((e) => {
          this.$events.fire("notification", {
            message: e.response.data.message,
            status: "error",
          });
        });
    },
    searchUnit: function (v) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.isLoading = true;
        this.get();
        // if (v.length > 2 || !v.length) this.get()
      }, 500);
    },
  },
};
</script>
<style lang="scss">
</style>

